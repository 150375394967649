import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/background-section"
import { FaDesktop, FaFacebook, FaGoogle } from "react-icons/fa"
import Img from "gatsby-image"

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  const featuredItems = [
    {
      id: 1,
      class: "col-10 col-sm-6 col-md-3 mx-auto featured-card -green",
      icon: <FaDesktop />,
      title: "รับทำเว็บไซต์ทุกประเภท",
      description:
        "เว็บไซต์ระบบตามสั่ง หรือ เว็บไซต์หน่วยาน เว็บขายของ ด้วย Wordpress",
      link: "/our-services/website",
    },
    {
      id: 2,
      class: "col-10 col-sm-6 col-md-3 mx-auto featured-card -blue",
      icon: <FaFacebook />,
      title: "แก้บัญชีโฆษณาถูกปิด",
      description: "บัญชีโฆษณาถูกปิด โปรโมทโพสต์ไม่ได้ เพจถูกปิดการมองเห็น",
      link: "/our-services/fix-facebook-page-advertising-account-closed",
    },
    {
      id: 4,
      class: "col-10 col-sm-6 col-md-3 mx-auto featured-card -blue",
      icon: <FaFacebook />,
      title: "แก้เพจถูกปิดการมองเห็น",
      description: "บัญชีโฆษณาถูกปิด โปรโมทโพสต์ไม่ได้ เพจถูกปิดการมองเห็น",
      link: "/our-services/fix-facebook-page-vision-disabled",
    },
    {
      id: 3,
      class: "col-10 col-sm-6 col-md-3 mx-auto featured-card -red",
      icon: <FaGoogle />,
      title: "Google My Business",
      description:
        "ลงทะเบียนธุรกิจของคุณกับ Google ช่วยให้ลูกค้ารู้จักธุรกิจ และนำทางลูกค้าไปถึงหน้าร้าน",
      link: "/our-services/google-my-business",
    },
  ]

  return (
    <Layout>
      <SEO
        title="ทูสกายซอฟต์ รับทำเว็บไซต์ และแก้ปัญหาเพจ Facebook"
        description="รับทำเว็บไซต์ทุกประเภท เว็บส่วนตัว เว็บขายของ ระบบภายในตามสั่ง ระบบตามสั่งออนไลน์ และบริการแก้ปัญหาเพจ Facebook โดนปิดบัญชีโฆษณา เพจถูกปิดการมองเห็น โปรโมทโพสต์ไม่ได้"
      />

      <BackgroundSection img={data.img.childImageSharp.fluid} title="" />

      <section className="pt-4" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="container">
          <h2 className="pb-3">บริการของเรา</h2>
          <div className="row">
            {featuredItems.map(item => {
              return (
                <div key={item.id.toString()} className={item.class}>
                  <div className="featured-card__wrap">
                    <div className="featured-card__line"></div>
                    <div className="featured-card__icon">
                      <Link to={item.link}>{item.icon}</Link>
                    </div>
                    <div className="featured-card__title">
                      <Link to={item.link}>{item.title}</Link>
                    </div>
                    <div className="featured-card__description">
                      {item.description}
                    </div>
                    <div className="featured-card__link">
                      <Link to={item.link}>ข้อมูลเพิ่มเติม</Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="pt-4">
        <div className="container">
          <h2 className="pb-3">บทความล่าสุด</h2>
          <div className="row">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title
              const featuredImage = node.frontmatter.featuredImage
                ? node.frontmatter.featuredImage.childImageSharp.fluid
                : "../../assets/images/about-wallpaper.png"
              return (
                <div
                  key={node.id}
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-4 mb-5"
                >
                  <article className="content-item -card">
                    <div className="pic">
                      <Link to={node.frontmatter.slug}>
                        <Img fluid={featuredImage} />
                      </Link>
                    </div>
                    <div className="info">
                      <header className="entry-header">
                        <h2 className="entry-title">
                          <Link to={node.frontmatter.slug}>{title}</Link>
                        </h2>
                      </header>
                    </div>
                  </article>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    img: file(relativePath: { eq: "cover-toskysoft.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            slug
            date(formatString: "YYYY-MM-DD")
            title
            metaDescription
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
